.update-section {
  margin: 0 auto;
  background-color: #cadff6;
  padding: 4rem 4rem 4rem 4rem;
  overflow-y: hidden;
}

.update-form h2 {
  padding: 1rem 0;
  color: #777;
  font-size: 4rem;
}
.update-form h4 {
  color: #777;
  padding: 1rem 0;
  font-size: 3rem;
}

.update-form {
  margin: 0 auto;
  padding: 1rem 0rem;
}

.update-form label {
  font-size: 3.5rem;
  display: block;
}
.update-form span {
  color: #666;
  display: block;
  text-align: left;
  margin-bottom: 1.5rem;
}
.update-form input {
  display: block;
  background-color: #fff;
  margin: 0 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}

.photo {
  display: flex;
  margin: 1rem auto;
  width: 15rem;
  height: 15rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: #e1e1e1;
  font-size: 2.5rem;
  color: #777;
  align-items: center;
}

.photo-btn {
  display: block;
  font-size: 2.5rem;
  margin: 2rem auto;
  width: auto;
  padding: 1.5rem 3rem;
  border: none;
  border-radius: 8rem;
  background-color: #5d8abb;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

.photo-img {
  display: flex;
  margin: 2rem auto;
  width: 16rem;
  height: 16rem;
  padding: 0rem;
  border-radius: 50%;
  font-size: 2.5rem;
  align-items: center;
}
.button-section {
  display: flex;
  margin: 0 auto;
  width: 75%;
}

.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 2rem;
}

.button-item {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 110em) {
  .button-section {
    margin: 0 auto;
  }
  .update-form label {
    font-size: 2.5rem;
  }
  .update-form input {
    font-size: 2.5rem;
  }
  .update-form {
    padding: 1rem 0 0 0;
  }

  .update-form span {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .update-section {
    padding: 1rem 4rem 1rem 4rem;
  }

  .update-form {
    padding: 0rem 0rem;
  }
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of px */
/*                        */
/**************************/

@media (max-width: 80em) {
  .button-section {
    margin: 0 auto;
    width: 95%;
  }
  .button-item {
    column-gap: 0;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .update-form label {
    font-size: 3rem;
  }
  .update-form input {
    font-size: 2.5rem;
  }
  .update-form {
    padding: 1rem 0 0 0;
  }
  .btn {
    display: block;
    font-size: 2.5rem;
  }

  .update-section {
    margin: 0 auto;
    background-color: #cadff6;
    padding: 4rem 4rem 4rem 4rem;
    overflow-y: hidden;
  }

  .update-form h2 {
    padding: 1rem 0;
    color: #777;
    font-size: 4rem;
  }
  .update-form h4 {
    color: #777;
    padding: 1rem 0;
    font-size: 3rem;
  }

  .update-form {
    margin: 0 auto;
    padding: 1rem 0rem;
  }
  .update-form span {
    font-size: 3rem;
  }

  .update-form label {
    font-size: 3rem;
  }
  .update-form input {
    font-size: 3rem;
  }
}
/**************************/
/*                        */
/* max width below 50em  */
/* or max width of px */
/*                        */
/**************************/

@media (max-width: 50em) {
  .button-section {
    margin: 0 auto;
    width: 100%;
  }
  .update-form {
    padding: 0rem;
  }

  .update-form span {
    font-size: 2rem;
  }

  .update-form label {
    font-size: 2rem;
  }
  .update-form input {
    padding: 1rem;
    font-size: 2rem;
  }
}

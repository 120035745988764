.signup-section {
  margin: 0 auto;
  width: 100%;
  background-color: #cadff6;
  padding: 4rem 4rem 0rem 0;
  overflow-y: hidden;
}

.signup-form {
  margin: 0 auto;
  padding: 2rem 0rem;
}

.signup-form label {
  font-size: 3.5rem;
  display: block;
}
.signup-form span {
  color: #666;
  display: block;
  text-align: left;
  margin-bottom: 1.5rem;
}
.signup-form input {
  display: block;
  background-color: #fff;
  margin: 0 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}

.button-section {
  padding: 2rem 4rem;
}
.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 4rem;
}

.button-item {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

.login-link {
  display: inline-block;
  font-size: 5rem;
  font-weight: 600;
  color: #5d8abb;
  text-decoration: none;
  padding: 4rem 0 4rem 0;
  margin-bottom: 2rem;
}

.login-link:hover {
  color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 110em   */
/* or max width of ???px  */
/*                        */
/**************************/
@media (max-width: 110em) {
  .signup-section {
    padding: 2rem 2rem 0rem 0;
  }
  .signup-form {
    padding: 1rem 0rem;
  }

  .signup-form label {
    font-size: 3rem;
  }
  .signup-form span {
    margin-bottom: 1.5rem;
  }
  .signup-form input {
    margin: 0 0 2rem 0;
    padding: 1.5rem 1.5rem;
    font-size: 2.5rem;
  }
}
/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 80em) {
  .login-link {
    font-size: 3.5rem;
    padding: 0;
  }
}
/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .signup-section {
    margin: 0 auto;
    padding: 0 2rem 0 0;
  }
  .login-link {
    font-size: 3rem;
    margin: 0 auto;
    padding: 1rem 0 2rem 0;
  }

  .signup-btn {
    margin: 1rem auto 1rem auto;
    font-size: 2.5rem;
    width: 30rem;
    padding: 2rem 2rem;
  }
  .signup-form {
    padding: 1rem 0;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 40em) {
  .signup-section {
    margin: 0 auto;
    padding: 0 2rem 0 0;
  }
  .login-link {
    font-size: 2.5rem;
    margin: 0 auto;
    padding: 0rem 0 0 0;
  }

  .signup-form {
    padding: 0;
    margin: 0 auto;
  }
  .signup-form label {
    font-size: 2rem;
  }
  .signup-form input {
    font-size: 2rem;
  }
  .signup-form label {
    font-size: 2rem;
  }
}

.copyright p {
  font-size: 3rem;
  color: #888;
  text-align: center;
  margin: 1rem 0 3rem 0;
}

/**************************/
/*                        */
/* max width below 80em   */
/* or max width of px  */
/*                        */
/**************************/

@media (max-width: 80em) {
  .copyright p {
    font-size: 2rem;
    margin: 1rem 0 1rem 0;
  }
}

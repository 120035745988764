* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  background-color: #cadff6;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Lato", sans-serif;
  text-align: center;
}
li {
  list-style-type: none;
}

.section {
  margin: 0 auto;
  width: 100%;
  background-color: #cadff6;
  padding: 0 4rem 0rem 4rem;
  overflow-y: hidden;
}

.container {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.pending {
  margin: 4rem auto;
  font-size: 3.5rem;
  font-weight: 600;
}
.pending p {
  font-size: 3.5rem;
  color: #888;
}

.errorMsg {
  margin: 4rem auto;
  font-size: 3.5rem;
  font-weight: 600;
  color: #ff5349;
}
.errorMsg p {
  font-size: 3.5rem;
  padding: 1rem 0;
  color: #ff5349;
}

/**************************/
/*                        */
/* max width below 60xem  */
/* or max width of 960xpx */
/*                        */
/**************************/

@media (max-width: 60em) {
  .section {
    padding: 0 2.5rem 2rem 2.5rem;
  }

  .pending p {
    font-size: 2.5rem;
    color: #888;
  }

  .errorMsg p {
    font-size: 2.5rem;
    padding: 1rem 0 0 0;
    color: #ff5349;
  }
}
/**************************/
/*                        */
/* max width below 60xem  */
/* or max width of 960xpx */
/*                        */
/**************************/

@media (max-width: 50em) {
  .section {
    padding: 0 2rem 2rem 2rem;
  }
}

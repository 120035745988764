.link-area-styles {
  margin: 6rem auto;
  height: 6rem;
  width: 35rem;
  background-color: #5d8abb;
  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.link-styles {
  font-size: 2.2rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.link-styles:hover a {
  background-color: #ee11db;
  transform: scale(1.1);
}

.link-area-styles:hover {
  cursor: pointer;
  background-color: #ee11db;
  color: #fff;
  transform: scale(1.1);
}

.stripe-payment-form {
  margin: 4rem auto;
}

.btn-area {
  width: 75%;
  margin: 3rem auto 0 auto;
  display: flex;
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .btn-area {
    width: 100%;
  }
}

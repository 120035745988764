.container {
  background-color: #cadff6;
  width: 60%;
  margin: 0 auto;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/

@media (max-width: 110em) {
  .container {
    width: 70%;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .container {
    width: 100%;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .container {
    width: 100%;
    margin: 0 auto;
  }
}

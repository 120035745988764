.profile-section {
  margin: 0 auto 0 auto;
  background-color: #cadff6;
  padding: 4rem 4rem 4rem 4rem;
  overflow-y: hidden;
  text-align: center;
}

.profile-section h2 {
  padding: 1rem;
  text-align: center;
  color: #666;
  font-size: 4rem;
}

.profile-section h4 {
  color: #888;
  padding: 1rem 0;
  font-size: 3rem;
  text-align: center;
}

.photo {
  display: flex;
  margin: 2rem auto;
  width: 16rem;
  height: 16rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: #e1e1e1;
  font-size: 2.5rem;
  color: #888;
  align-items: center;
}

.photo-img {
  display: flex;
  margin: 2rem auto;
  width: 16rem;
  height: 16rem;
  padding: 0rem;
  border-radius: 50%;
  font-size: 2.5rem;
  align-items: center;
}

.profile-content {
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.button-section {
  margin: 0 auto;
  width: 50%;
}
.button-item {
  margin: 0 auto;
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of px */
/*                        */
/**************************/

@media (max-width: 80em) {
  .button-section {
    margin: 0 auto;
    width: 75%;
  }
  .button-item {
    column-gap: 0;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .profile-section {
    margin: 0 auto 0 auto;
    padding: 2rem 1rem 2rem 1rem;
  }
  .profile-content {
    margin: 0 auto;
    text-align: center;
  }

  .profile-content h2 {
    text-align: center;
    font-size: 2.8rem;
  }
  .profile-content h4 {
    text-align: center;
    font-size: 2.2em;
  }

  .btn {
    font-size: 2.5rem;
  }
}

/**************************/
/*                        */
/* max width below 40xem  */
/* or max width of 640xpx */
/*                        */
/**************************/
@media (max-width: 40em) {
  .profile-content h2 {
    font-size: 2.2rem;
  }
  .profile-content h4 {
    padding: 1rem 0;
    margin: 0;
    text-align: center;
    font-size: 1.8rem;
  }
}

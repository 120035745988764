.signup-section {
  margin: 0 auto;
  width: 100%;
  background-color: #cadff6;
  padding: 4rem 4rem 0rem 0;
  overflow-y: hidden;
}

.signup-form {
  margin: 0 auto;
  padding: 2rem 0rem;
}

.signup-form label {
  font-size: 3.5rem;
  display: block;
}
.signup-form span {
  color: #666;
  display: block;
  text-align: left;
}
.signup-form input {
  display: block;
  background-color: #fff;
  margin: 1rem 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}

.checkbox {
  padding-top: 3rem;
  display: grid;
  font-size: 3rem;
  gap: 4rem;
  flex-direction: row;
  grid-template-columns: 6rem 1fr;
  justify-content: left;
  text-align: left;
  align-items: flex-start;
}

.checkbox-input {
  height: 6rem;
  align-self: center;
  margin-bottom: 0;
  margin-top: 2rem;
}

.checkbox-text {
  justify-content: flex-start;
  align-content: left;
  line-height: 3rem;
}

.checkbox-text a {
  font-size: 3rem;
  text-decoration: none;
  color: #5d8abb;
  font-weight: 600;
  display: inline-block;
  padding: 1rem 1rem;
}

.checkbox-text p {
  font-size: 3rem;
  display: inline-block;
}

.button-section {
  padding: 4rem;
}
.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 2rem;
}

.button-item {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

/**************************/
/*                        */
/* max width below 110em   */
/* or max width of ???px  */
/*                        */
/**************************/
@media (max-width: 110em) {
  .signup-section {
    padding: 2rem 2rem 0rem 0;
  }
  .signup-form {
    padding: 1rem 0rem;
  }

  .signup-form label {
    font-size: 3rem;
  }
  .signup-form span {
    margin-bottom: 1.5rem;
  }
  .signup-form input {
    margin: 0 0 2rem 0;
    padding: 1.5rem 1.5rem;
    font-size: 2.5rem;
  }
  .checkbox-text {
    line-height: 3.5rem;
  }

  .checkbox-text a {
    font-size: 2.5rem;
    padding: 0 1rem;
  }

  .checkbox-text p {
    font-size: 2.5rem;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .title {
    font-size: 4rem;
    padding: 4rem 2rem 2rem 2rem;
  }

  .btn {
    margin: 1rem auto 2rem auto;
    font-size: 3rem;
    width: 30rem;
    padding: 2rem 2rem;
  }

  .checkbox-text a {
    font-size: 2.5rem;
  }

  .checkbox-text p {
    font-size: 2.5rem;
  }
  .checkbox-text {
    font-size: 2.5rem;
  }

  .checkbox {
    padding-top: 1rem;
    font-size: 2.5rem;
    gap: 4rem;
    grid-template-columns: 4rem 1fr;
  }

  .checkbox-input {
    height: 4rem;
    margin-top: 2rem;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 40em) {
  .signup-section {
    margin: 0 auto;
    padding: 0 2rem 0 0;
  }
  .login-link {
    font-size: 2.5rem;
    margin: 0 auto;
    padding: 0rem 0 2rem 0;
  }

  .signup-form {
    padding: 0;
    margin: 0 auto;
  }
  .signup-form label {
    font-size: 2rem;
  }
  .signup-form input {
    font-size: 2rem;
  }
  .signup-form label {
    font-size: 2rem;
  }
  .checkbox-text {
    line-height: 3rem;
  }

  .checkbox-text a {
    font-size: 2rem;
    padding: 0 0.5rem;
  }

  .checkbox-text p {
    font-size: 2rem;
  }
  .checkbox {
    padding-top: 1rem;
    font-size: 2rem;
    gap: 2rem;
    grid-template-columns: 4rem 1fr;
  }
}

.navbar-container {
  /* container for the navbar, includes logo/name area and then the button area */
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background-color: #fff;
}

/* contains the navigation bar with buttons for menu items */
.navbar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 0 auto;
  background-color: #fff;
  gap: 2rem;
}

/* styles the logo and name in the navbar left side */
.nav-brand-area {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 2rem;
  background-color: #fff;
}

.img-logo,
.img-logo:active,
.img-logo:link,
.img-logo:visited {
  width: 9rem;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}

.img-logo:hover {
  background-image: linear-gradient(
      to right bottom,
      rgba(238, 17, 219, 0.6),
      rgba(238, 17, 219, 0.6)
    ),
    url("../images/go-phor-logo-withframe.webp");
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
}

.nav-brand-name,
.nav-brand-name:active,
.nav-brand-name:link,
.nav-brand-name:visited {
  text-align: center;
  color: #5d8abb;
  text-decoration: none;
  font-size: 5rem;
  font-weight: 700;
  transition: all 0.3s;
}
.nav-brand-name:hover {
  color: rgb(238, 17, 220);
  transform: scale(1.1);
}

.navmenu {
  justify-content: right;
}

.mobile-button-area button {
  background: none;
  border: none;
}

.icon {
  height: 5rem;
  width: 5rem;
  stroke: #5d8abb;
  stroke-width: 2;
  border: none;
  background: none;
}

.menu-btn {
  display: none;
}

.close-btn {
  display: none;
}

/*contains the menu buttons */
.button-area {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.welcome-msg h1 {
  font-size: 1.8rem;
  color: #555;
}

.letter {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  height: 5rem;
  width: 5rem;
  color: #fff;
  border-radius: 50%;
  background-color: #5d8abb;
}

.letter:hover {
  background-color: rgb(238, 17, 220);
  transform: scale(1.1);
}
/**************************/
/*                        */
/* max width below 110em   */
/* or max width of ???px  */
/*                        */
/**************************/
@media (max-width: 110em) {
  .navbar-section {
    height: 11rem;
    margin-top: 0rem;
  }
}

/**************************/
/*                        */
/* max width below 80em   */
/* or max width of 1280px */
/*                        */
/**************************/

@media (max-width: 80em) {
  .navbar-section {
    height: 11rem;
    margin-top: 0;
  }
  .navbar-section.open {
    height: auto;
    align-items: flex-start;
  }

  .mobile-button-area button {
    justify-content: right;
  }
  .nav-brand-area {
    justify-content: left;
    align-items: center;
    gap: 2rem;
  }

  .menu-btn {
    display: block;
    z-index: 88888;
  }
  .menu-btn.open {
    display: none;
  }

  .close-btn.open {
    display: block;
  }

  .button-area {
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    gap: 1rem;
    height: 0;
  }

  .button-area.open {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
    flex-grow: 1;
    height: auto;
  }
}

/**************************/
/*                        */
/* max width below 50xem  */
/* or max width of 800xpx */
/*                        */
/**************************/

@media (max-width: 50em) {
  .navbar-section {
    padding: 1rem;
  }
  .nav-brand-area {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #fff;
  }

  .img-logo,
  .img-logo:active,
  .img-logo:link,
  .img-logo:visited {
    width: 5rem;
  }

  .nav-brand-name,
  .nav-brand-name:active,
  .nav-brand-name:link,
  .nav-brand-name:visited {
    font-size: 2.5rem;
  }
}

/**************************/
/*                        */
/* max width below 40xem  */
/* or max width of 640xpx */
/*                        */
/**************************/

@media (max-width: 40em) {
  .nav-brand-area {
    gap: 1rem;
  }

  .img-logo,
  .img-logo:active,
  .img-logo:link,
  .img-logo:visited {
    width: 3rem;
    margin-right: 1rem;
  }

  .nav-brand-name,
  .nav-brand-name:active,
  .nav-brand-name:link,
  .nav-brand-name:visited {
    font-size: 2rem;
  }
}

.payment-card {
  background-color: #fff;
  margin: 4rem;
  padding: 4rem 8rem;
}
.payment-card h1 {
  padding: 2rem;
  font-size: 4rem;
  color: #666;
}
.payment-grid p {
  padding: 1rem;
  font-size: 3rem;
  color: #777;
}
.payment-grid {
  display: grid;
  grid-template-columns: 1fr 5rem;
}
.delete-icon {
  background-image: url("../images/delete.svg");
  display: block;
  margin-left: 1rem;
  background-color: #fff;
  width: 5rem;
  height: 5rem;
  transition: all 0.3s;
}

.delete-icon:hover {
  background-image: url("../images/deletePink.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transform: scale(1.2);
}

.button-area {
  display: flex;
  margin: 0 auto;
  width: 95%;
  align-items: center;
}

.btn {
  display: block;
  font-size: 3rem;
  margin: 2rem auto 3rem auto;
  width: 20rem;
  padding: 2rem 4rem;
  border: none;
  border-radius: 8rem;
  background-color: #5d8abb;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}
.btn:hover,
.photo-btn:hover {
  cursor: pointer;
  color: #fff;
  background-color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .payment-grid {
    grid-template-columns: 1fr 8rem;
  }
}

/**************************/
/*                        */
/* max width below 60xem  */
/* or max width of 960xpx */
/*                        */
/**************************/

@media (max-width: 60em) {
  .button-area {
    width: 95%;
    align-items: center;
  }
  .payment-grid p {
    font-size: 1.7rem;
  }
  .payment-card h1 {
    padding: 1rem;
    font-size: 2.5rem;
  }
  .payment-card {
    background-color: #fff;
    margin: 2rem;
    padding: 2rem 1rem;
  }
}

/**************************/
/*                        */
/* max width below 60xem  */
/* or max width of 960xpx */
/*                        */
/**************************/

@media (max-width: 50em) {
  .button-area {
    width: 95%;
    align-items: center;
  }

  .payment-card p {
    font-size: 1.7rem;
  }
  .payment-card h1 {
    padding: 1rem;
    font-size: 2.5rem;
  }
  .payment-card {
    background-color: #fff;
    margin: 2rem;
    padding: 2rem 1rem;
  }
}

.not-found {
  width: 80%;
  margin: 0 auto;
}

.not-found p {
  padding: 2rem 0;
  font-size: 3.5rem;
  color: #888;
}

.anchor-style {
  padding: 2rem;
  height: 8rem;
  width: 34rem;
  margin: 2rem auto 2rem auto;
  background-color: #5d8abb;
  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.anchor-text {
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.anchor-style:hover {
  cursor: pointer;
  background-color: #ee11db;
  color: #fff;
  transform: scale(1.1);
}

.anchor-text a {
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

/**************************/
/*                        */
/* max width below 80em   */
/* or max width of px  */
/*                        */
/**************************/

@media (max-width: 80em) {
  .anchor-text a {
    font-size: 3rem;
  }
  .anchor-style {
    height: 8rem;
    width: 26rem;
  }
}

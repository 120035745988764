.logo {
  fill: none;
  stroke: #ee11db;
  stroke-width: 0.3rem;
  height: 8rem;
  width: 8rem;
  margin: 4rem 3rem 2rem 3rem;
}
/**************************/
/*                        */
/* max width below 80em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 80em) {
  .logo {
    margin-bottom: 2rem;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .logo {
    height: 6rem;
    width: 6rem;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .logo {
    height: 5rem;
    width: 5rem;
    margin: 4rem 2rem 2rem 2rem;
  }
}

/**************************/
/*                        */
/* max width below 40em   */
/* or max width of 640px  */
/*                        */
/**************************/

@media (max-width: 40em) {
  .logo {
    height: 3.5rem;
    width: 3.5rem;
    margin: 2rem 1rem 2rem 1rem;
  }
}

.heading-title {
  font-size: 4.5rem;
  color: #666;
  font-weight: 700;
  font-style: italic;
  text-align: center;
  background-color: #cadff6;
  padding: 6rem 0 3rem 0;
}

/**************************/
/*                        */
/* max width below 110em   */
/* or max width of ???px  */
/*                        */
/**************************/
@media (max-width: 110em) {
  .heading-title {
    font-size: 4rem;
    padding: 3rem 0 2rem 0;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .heading-title {
    font-size: 4rem;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .heading-title {
    font-size: 3rem;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 30em) {
  .heading-title {
    font-size: 2.6rem;
  }
}

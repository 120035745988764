.location-section {
  padding: 3rem;
  margin: 4rem auto;
  padding: 4rem 4rem;
  background-color: #fff;
}

.location-section h3 {
  padding: 2rem 1rem 3rem 1rem;
  background-color: #fff;
  font-size: 3rem;
  color: #666;
}

.location-grid {
  display: grid;
  grid-template-columns: 7rem 1fr 6rem 6rem;
  gap: 1rem;
}

.location-grid h1 {
  padding: 3rem 1rem 2rem 1rem;
  background-color: #fff;
  font-size: 3rem;
  color: #666;
  font-weight: 800;
}

.location-edit {
  display: block;
  background-image: url("../images/edit.svg");
  background-color: #fff;
  width: 5rem;
  height: 5rem;
  transition: all 0.3s;
}

.location-edit:hover {
  background-image: url("../images/editPink.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transform: scale(1.1);
}

.location-delete {
  background-image: url("../images/delete.svg");
  display: block;
  background-color: #fff;
  width: 5rem;
  height: 5rem;
  transition: all 0.3s;
}

.location-delete:hover {
  background-image: url("../images/deletePink.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transform: scale(1.1);
}

.button-area {
  display: flex;
  margin: 0 auto;
  width: 55%;
  align-items: center;
}

.btn {
  display: block;
  font-size: 3rem;
  margin: 2rem auto 3rem auto;
  width: 20rem;
  padding: 2rem 4rem;
  border: none;
  border-radius: 8rem;
  background-color: #5d8abb;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}
.btn:hover,
.photo-btn:hover {
  cursor: pointer;
  color: #fff;
  background-color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 80em   */
/* or max width of px  */
/*                        */
/**************************/

@media (max-width: 80em) {
  .button-area {
    width: 75%;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .button-area {
    width: 75%;
  }
  .location-section {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .location-section h3 {
    padding: 1rem;
    font-size: 2rem;
  }

  .location-grid {
    grid-template-columns: 3rem 1fr 3rem 4rem;
    gap: 0.5rem;
  }

  .location-grid img {
    margin-top: 1rem;
    width: 3rem;
    height: 3rem;
  }

  .location-grid h1 {
    font-size: 2rem;
  }

  .location-edit {
    background-size: 2.5rem;
    background-repeat: no-repeat;
    background-position: center;
  }

  .location-delete {
    background-size: 2.5rem;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .button-area {
    width: 95%;
  }
}

.delivery-date p {
  padding-bottom: 2rem;
  font-weight: 600;
  color: #888;
}

.delivery-grid {
  display: grid;
  padding: 1rem;
  grid-template-columns: 6rem 1fr;
}

.delivery-flex {
  padding: 1rem 0 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}

.status-detail {
  display: block;
  font-size: 2.5rem;
  font-style: italic;
  color: #777;
}

.status-logo {
  height: 5rem;
  width: 5rem;
  background-image: url("../images/viewPhotoBlue.svg");
  transition: all 0.3s;
}

.status-photo {
  height: 40rem;
  width: 40rem;
  padding: 1rem;
}

.status-logo:hover {
  height: 5rem;
  width: 5rem;
  padding: 1rem;
  background-image: url("../images/viewPhotoPink.svg");
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transform: scale(1.2);
}

.price-code p {
  padding-bottom: 1rem;
  font-weight: 600;
  color: #888;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/

@media (max-width: 110em) {
  .delivery-grid {
    grid-template-columns: 3rem 1fr;
  }

  .delivery-grid img {
    height: 3.5rem;
    width: 3.5rem;
  }
  .delivery-flex {
    gap: 1rem;
  }
  .status-logo {
    margin: 0 0.5rem;
  }

  .status-logo img {
    height: 3rem;
    width: 3rem;
  }
  .status-photo {
    height: 30rem;
    width: 30rem;
    padding: 1rem;
  }

  .price-code p {
    font-size: 1.8rem;
  }
  .status-detail {
    font-size: 1.7rem;
  }
  .price-code .pfont {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .delivery-grid {
    grid-template-columns: 3rem 1fr;
  }
  .delivery-grid img {
    height: 3.5rem;
    width: 3.5rem;
  }
  .delivery-flex {
    gap: 1rem;
  }
  .status-logo {
    margin-right: 0.5rem;
  }

  .status-logo img {
    height: 3rem;
    width: 3rem;
  }
  .status-photo {
    height: 30rem;
    width: 30rem;
    padding: 1rem;
  }

  .price-code p {
    font-size: 1.8rem;
  }
  .status-detail {
    font-size: 1.7rem;
  }
  .price-code .pfont {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .delivery-flex {
    gap: 1rem;
    padding: 1rem 0 3rem 0;
  }
  .status-photo {
    height: 20rem;
    width: 20rem;
    padding: 0.5rem;
  }
  .status-logo {
    padding: 0;
    margin: 0;
    transform: scale(0.7);
  }
  .status-logo:hover {
    transform: scale(0.8);
  }
  .status-detail {
    font-size: 1.5rem;
  }
}

.intro {
  padding: 8rem 15rem 6rem 15rem;
}

.intro p {
  font-size: 4rem;
  color: #555;
  font-weight: 500;
}
.intro a {
  display: inline-block;
  font-size: 3.5rem;
  font-weight: 500;
  color: #5d8abb;
  text-decoration: none;
  margin-left: 2.3rem;
}
.intro a:hover {
  color: #ee11db;
  transform: scale(1.1);
}

.link-section {
  display: grid;
  grid-template-columns: 1.8fr 1.2fr;
  gap: 4rem;
  align-items: center;
  justify-content: center;
}

.link-item {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.learn-more {
  display: flex;
  flex-direction: column;
}

.legal {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.subheading {
  font-size: 4.5rem;
  padding: 3rem;
  font-weight: 500;
}

.legal-link {
  padding: 1rem 0;
}

.legal-link a {
  display: inline-block;
  padding-left: 1.5rem;
  font-size: 3.5rem;
  color: #5d8abb;
  font-weight: 700;
  text-decoration: none;
}

.legal-link:hover a {
  cursor: pointer;
  color: #ee11db;
  transform: scale(1.1);
}

.icon {
  padding-bottom: 1rem;
  height: 4.2rem;
  width: 4.2rem;
  stroke: #5d8abb;
  stroke-width: 3;
}

.link-img {
  padding: 4rem;
  border-radius: 15rem;
}

/* .youtube {
  font-size: 4.5rem;
  padding: 12rem 0 8rem 0;
  font-weight: 500;
}

.video-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10rem;
}
.video-item {
  display: flex;
}
.video-item:nth-child(3),
.video-item:first-child {
  justify-content: flex-end;
}

.video-links h3 {
  margin: 2rem auto;
} */

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 80em) {
  .intro {
    margin: 3rem auto 0 auto;
    padding: 4rem 6rem;
  }
  .intro p {
    font-size: 3rem;
  }
  .intro a {
    font-size: 3rem;
    font-weight: 500;
    margin: 0 auto;
  }

  .link-section {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .link-item {
    gap: 1rem;
  }

  .subheading {
    font-size: 3rem;
    display: inline;
    margin: 1rem 3rem 0 3rem;
    padding: 1rem 0 2rem 0;
  }
  .link-item {
    display: flex;
  }

  .link-img {
    margin: 0 auto;
    height: 50rem;
    width: 35rem;
    border-radius: 15rem;
  }
  .legal {
    margin: 0 auto;
    padding: 0;
  }

  .legal-link a {
    font-size: 2.8rem;
    padding-left: 1rem;
  }

  .icon {
    padding-bottom: 1rem;
    height: 3.5rem;
    width: 3.5rem;
  }
}
/**************************/
/*                        */
/* max width below 50xem  */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .legal-link a {
    font-size: 2rem;
  }

  .subheading {
    font-size: 2.5rem;
    margin: 0 2rem;
  }
  .intro {
    margin: 2rem auto 0 auto;
    padding: 2rem 3rem;
  }
}

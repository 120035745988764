.link-item {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.learn-more {
  display: flex;
  flex-direction: column;
}

.link-section {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-right: 4rem;
}

.link-img {
  margin: 4rem 8rem 2rem 4rem;
  border-radius: 10rem;
}
.subheading {
  display: inline;
  font-size: 4.5rem;
  padding: 3rem 1rem 3rem 1rem;
  font-weight: 500;
  color: #555;
}

.button-section {
  padding: 4rem 2rem 0 2rem;
}

.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 3rem;
  text-align: center;
}

.login-text {
  padding-top: 3rem;
}

.button-item {
  display: flex;
  width: 65%;
  align-items: center;
  margin: 0 auto;
}

.intro {
  display: inline-block;
  margin: 4rem auto;
}
.intro p {
  font-size: 5rem;
  color: #666;
  font-weight: 500;
}
.intro a {
  display: inline-block;
  font-size: 5rem;
  font-weight: 500;
  color: #5d8abb;
  text-decoration: none;
  margin-left: 2.5rem;
}
.intro a:hover {
  color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .subheading {
    margin: 1rem auto 1rem auto;
    font-size: 3.5rem;
    padding: 4rem 1rem 1rem 1rem;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 70em) {
  .button-item {
    width: 95%;
  }
  .subheading {
    font-size: 3rem;
    text-align: center;
    margin: 1rem 3rem 0 3rem;
  }

  .link-section {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }

  .link-img {
    margin: 5rem auto 0 auto;
    height: 35rem;
    width: 27rem;
    border-radius: 10rem;
  }

  .button-section {
    margin: 0 auto;
    text-align: center;
    padding: 0 2rem 0 2rem;
  }

  .button-section p {
    font-size: 2.5rem;
    text-align: center;
  }

  .intro {
    margin: 2rem auto;
  }
  .intro p {
    font-size: 2.5rem;
  }
  .intro a {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0 auto;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .button-item {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
  .subheading {
    text-align: center;
    margin: 1rem 2rem 0 2rem;
  }
}

.delivery-card {
  background-color: #fff;
  margin: 2rem 4rem 4rem 4rem;
  padding: 2rem 4rem;
}
.delivery-card h1 {
  padding: 2rem;
  font-size: 3.5rem;
  color: #666;
}
.delivery-card p {
  padding: 1rem;
  font-size: 3rem;
  color: #777;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/

@media (max-width: 110em) {
  .delivery-card {
    background-color: #fff;
    margin: 1rem;
    padding: 1rem 2rem 0.5rem 1.6rem;
  }
  .delivery-card h1 {
    padding: 1rem;
    font-size: 2.7rem;
  }
  .delivery-card p {
    font-size: 1.8rem;
  }
}

/**************************/
/*                        */
/* max width below 60em   */
/* or max width of 960px  */
/*                        */
/**************************/

@media (max-width: 60em) {
  .delivery-card {
    background-color: #fff;
    margin: 1rem;
    padding: 1rem 1.3rem 1rem 1.3rem;
  }
  .delivery-card h1 {
    padding: 1rem;
    font-size: 3rem;
  }
  .delivery-card p {
    font-size: 1.8rem;
  }
}

/**************************/
/*                        */
/* max width below 50em   */
/* or max width of 800px  */
/*                        */
/**************************/

@media (max-width: 50em) {
  .delivery-card {
    margin: 1rem 0;
    padding: 1rem 0.3rem 1rem 0.3rem;
  }
  .delivery-card h1 {
    padding: 1rem;
    font-size: 2.5rem;
  }
}

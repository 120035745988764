.locations-section {
  margin: 0 auto;
  width: 100%;
  background-color: #cadff6;
  padding: 4rem 4rem 4rem 0;
  overflow-y: hidden;
}

.subheading p {
  font-size: 3rem;
  color: #888;
  font-weight: 600;
  text-align: center;
  padding: 2rem 0 2rem 0;
}
.add-new-location {
  display: flex;
  justify-content: center;
}
.add-new-location p {
  display: flex;
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
}

.add-icon {
  background-image: url("../images/addIcon.svg");
  background-position: center;
  background-repeat: none;
  margin-left: 1rem;
  width: 4rem;
  height: 4rem;
}

.btn-area-styles {
  height: 6rem;
  width: 26rem;
  background-color: #5d8abb;
  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 2rem auto;
  transition: all 0.3s;
}

.btn-area-styles:hover {
  background-color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .locations-section {
    padding: 1rem 1rem 1rem 0;
  }
  .btn-area-styles {
    margin: 0 auto;
    height: 6rem;
    width: 20rem;
    padding-top: 1rem;
  }
  .add-new-location p {
    font-size: 2.8rem;
    font-weight: 700;
    color: #fff;
  }
}

.link-area-styles {
  height: 6rem;
  width: 15rem;
  background-color: #5d8abb;
  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.link-styles {
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.link-styles:hover a {
  background-color: #ee11db;
  transform: scale(1.1);
}

.link-area-styles:hover {
  cursor: pointer;
  background-color: #ee11db;
  color: #fff;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 80em   */
/* or max width of 1280px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .button-styles {
    height: auto;
    background-color: none;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }

  .button-styles:hover {
    cursor: pointer;
    color: #ee11db;
    background-color: none;
    border: none;
    transform: scale(1.1);
  }
}

/**************************/
/*                        */
/* max width below 50xem  */
/* or max width of 800xpx */
/*                        */
/**************************/
@media (max-width: 50em) {
  .link-styles {
    font-size: 1.7rem;
  }
  .link-area-styles {
    margin: 0.5rem 0;
    gap: 2rem;
    height: 4rem;
    width: 11rem;
  }
}

.pac-container {
  background-color: #b0d6fe;
  color: #555;
  font-family: "Lato", sans-serif;
}
.maparea {
  padding: 0 0 4rem 0;
}

.pac-item,
.pac-item-query {
  padding: 1rem;
  font-size: 2rem;
  color: #555;
}
.pac-item:hover {
  background-color: #cadff6;
}
.pac-matched {
  color: #555;
}
.maparea {
  margin: 6rem auto 0 auto;
  width: 65%;
}
.location-choice {
  margin-bottom: 4rem;
}
.location-choice p {
  font-family: "Lato", sans-serif;
  text-align: left;
  font-size: 3rem;
}
.location-choice input {
  font-family: "Lato", sans-serif;
  font-size: 3rem;
  padding-left: 1rem;
}

.maparea input {
  margin: 2rem auto 0 auto;
  width: 100%;
  height: 6rem;
  font-size: 3rem;
}

/**************************/
/*                        */
/* max width below 50em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 40em) {
  .location-choice p {
    font-size: 2rem;
  }
  .location-choice input {
    font-size: 2rem;
  }
}

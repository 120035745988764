.link-area-styles {
  margin: 1rem auto 2rem auto;
  height: 8rem;
  width: 24rem;
  background-color: #5d8abb;
  cursor: pointer;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.link-styles {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;

  text-decoration: none;
}
.link-styles a {
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}

.link-area-styles:hover {
  cursor: pointer;
  background-color: #ee11db;
  color: #fff;
  transform: scale(1.1);
}
.signup-btn-home {
  width: 22rem;
  font-size: 4rem;
}
.signup-btn {
  width: 36rem;
  font-size: 4rem;
}
.login-btn-home {
  width: 20rem;
  font-size: 4rem;
}
.login-btn {
  width: 20rem;
  font-size: 4rem;
}
.update-location-btn {
  width: 38rem;
  font-size: 4rem;
}
.cancel-btn {
  width: 20rem;
  font-size: 4rem;
}
.btn-signup2 {
  width: 30rem;
  font-size: 4rem;
}

.btn-disabled-login {
  font-size: 4rem;
  width: 20rem;
  color: #fff;
  background-color: #9e9e9e;
  transition: none;
}
.btn-disabled {
  font-size: 4rem;
  width: 40rem;
  color: #fff;
  background-color: #9e9e9e;
  transition: none;
}

.btn-disabled-signup2 {
  font-size: 4rem;
  width: 30rem;
  color: #fff;
  background-color: #9e9e9e;
  transition: none;
}
.btn-disabled-signup2:hover {
  transform: none;
  background-color: #9e9e9e;
}

.btn-disabled:hover {
  transform: none;
  background-color: #9e9e9e;
}
.btn-disabled-login:hover {
  transform: none;
  background-color: #9e9e9e;
}
.profile-btn {
  width: 28rem;
  font-size: 4rem;
}
.edit-profile-btn {
  width: 34rem;
  font-size: 4rem;
}

.delivery {
  width: 36rem;
  font-size: 3.5rem;
}
.change-photo {
  display: flex;
  padding: 1.5rem 0;
  justify-content: center;
  width: 30rem;
  height: 6rem;
  font-size: 2.4rem;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 110em) {
  .link-area-styles {
    margin: 1rem auto 2rem auto;
    height: 7rem;
    width: 18rem;
  }

  .link-styles {
    font-size: 3rem;
  }
  .delivery {
    width: 28rem;
    padding: 1rem 0;
    height: 6rem;
    font-size: 2.8rem;
  }
  .signup-btn {
    width: 28rem;
    font-size: 2.8rem;
  }
  .btn-signup2 {
    width: 20rem;
    font-size: 2.8rem;
  }

  .btn-disabled-login {
    font-size: 2.8rem;
    width: 16rem;
    color: #fff;
    background-color: #9e9e9e;
    transition: none;
  }
  .btn-disabled {
    font-size: 2.8rem;
    width: 28rem;
  }

  .btn-disabled-signup2 {
    font-size: 2.8rem;
    width: 20rem;
  }
  .login-btn {
    width: 16rem;
    font-size: 2.8rem;
  }

  .update-location-btn {
    width: 30rem;
    font-size: 3rem;
  }
  .cancel-btn {
    width: 16rem;
    font-size: 3rem;
  }
  .profile-btn {
    width: 22rem;
    font-size: 3rem;
  }
  .edit-profile-btn {
    width: 28rem;
    font-size: 3rem;
  }
  .change-photo {
    display: flex;
    padding: 1.5rem 0;
    justify-content: center;
    width: 34rem;
    height: 6rem;
    font-size: 2.6rem;
  }
}
/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .signup-btn-home {
    width: 17rem;
    font-size: 3rem;
  }

  .login-btn-home {
    width: 15rem;
    font-size: 3rem;
  }
  .edit-profile-btn {
    width: 25rem;
    font-size: 3rem;
  }
  .cancel-btn {
    width: 15rem;
    font-size: 3rem;
  }
  .update-location-btn {
    width: 28rem;
    font-size: 3rem;
  }
}
/**************************/
/*                        */
/* max width below 50em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 60em) {
  .link-area-styles {
    margin: 1rem auto 2rem auto;
    height: 7rem;
    width: 14rem;
  }
  .link-styles {
    font-size: 2rem;
  }
}
/**************************/
/*                        */
/* max width below 50em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 50em) {
  .link-area-styles {
    margin: 1rem auto 2rem auto;
    height: 7rem;
    width: 12rem;
  }

  .link-styles {
    font-size: 2rem;
  }

  .cancel-btn {
    width: 9rem;
    font-size: 2rem;
  }
  .update-location-btn {
    width: 18rem;
    font-size: 2rem;
  }
  .delivery {
    width: 20rem;
    height: 7rem;
    font-size: 2rem;
  }
  .edit-profile-btn {
    width: 16rem;
    font-size: 2rem;
  }
}

/**************************/
/*                        */
/* max width below 50em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 40em) {
  .link-area-styles {
    margin: 1rem auto 2rem auto;
    height: 7rem;
    width: 9rem;
  }
  .delivery {
    width: 18rem;
    height: 7rem;
    font-size: 2rem;
  }

  .link-styles {
    font-size: 2rem;
  }
  .change-photo {
    width: 16rem;
    height: 6rem;
    font-size: 1.8rem;
  }
  .signup-btn {
    width: 18rem;
  }
  .btn-disabled {
    width: 18rem;
  }
  .btn-disabled-signup2 {
    width: 12rem;
  }
  .btn-signup2 {
    width: 12rem;
  }
}

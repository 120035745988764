.login-section {
  margin: 0 auto;
  width: 100%;
  background-color: #cadff6;
  padding: 3rem;
  overflow-y: hidden;
  text-align: center;
}

.login-form {
  margin: 0 auto;
  padding: 2rem 0rem;
}

.login-form label {
  font-size: 3.5rem;
  display: block;
}
.login-form span {
  color: #666;
  display: block;
  text-align: left;
  margin-bottom: 1.5rem;
}
.login-form input {
  display: block;
  background-color: #fff;
  margin: 0 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}

.button-section {
  padding-left: 4rem;
  padding-right: 4rem;
}
.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 2rem;
}

.button-item {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

.reset-text {
  padding: 1rem 0 3rem 0;
  font-size: 3.5rem;
}

.reset-link {
  display: block;
  margin: 0 auto;
  padding: 0 0 2rem 0;
  font-size: 4rem;
  font-weight: 600;
  color: #5d8abb;
  text-decoration: none;
}
.reset-link:hover {
  color: #ee11db;
  transform: scale(1.1);
}

.signup-link {
  display: inline-block;
  font-size: 4.5rem;
  font-weight: 600;
  color: #5d8abb;
  text-decoration: none;
  margin-bottom: 2rem;
}

.signup-link:hover {
  color: #ee11db;
  transform: scale(1.1);
}

/**************************/
/*                        */
/* max width below 110em   */
/* or max width of ???px  */
/*                        */
/**************************/
@media (max-width: 110em) {
  .login-form {
    margin: 0 auto;
    padding: 1rem 0rem;
  }

  .login-form label {
    font-size: 3rem;
  }
  .login-form span {
    margin-bottom: 1.5rem;
  }
  .login-form input {
    margin: 0 0 2rem 0;
    padding: 1.5rem 1.5rem;
    font-size: 2.5rem;
  }

  .reset-link {
    font-size: 3.5rem;
  }
  .signup-link {
    font-size: 4rem;
  }
  .reset-text {
    font-size: 3rem;
  }
}

/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 60em) {
  .signup-link {
    font-size: 3rem;
  }
  .reset-text {
    font-size: 3rem;
  }
  .reset-link {
    font-size: 3rem;
  }

  .login-form {
    margin: 0 auto;
    padding: 1rem 0rem;
    font-size: 2.5rem;
  }

  .login-form label {
    font-size: 2rem;
  }
  .login-form span {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .login-form input {
    margin: 0 0 2rem 0;
    padding: 1rem;
    font-size: 2.5rem;
  }
}
/**************************/
/*                        */
/* max width below 60em  */
/* or max width of 960px */
/*                        */
/**************************/

@media (max-width: 40em) {
  .signup-link {
    font-size: 2rem;
  }
  .reset-text {
    font-size: 2rem;
  }
  .reset-link {
    font-size: 2rem;
  }

  .login-form {
    padding: 0;
    font-size: 2rem;
  }

  .login-form label {
    font-size: 2rem;
  }
  .login-form span {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .login-form input {
    margin: 0 0 2rem 0;
    padding: 1rem;
    font-size: 2rem;
  }
}

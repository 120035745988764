.location-form {
  margin: 0 auto;
  padding: 2rem 0rem;
  font-family: "Lato", sans-serif;
}

.location-form label {
  font-size: 3.5rem;
  display: block;
  font-family: "Lato", sans-serif;
}
.location-form span {
  color: #666;
  font-family: "Lato", sans-serif;
  display: block;
  text-align: left;
  margin-bottom: 1.5rem;
}
.location-form input {
  display: block;
  font-family: "Lato", sans-serif;
  background-color: #fff;
  margin: 0 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}
.location-form textarea {
  display: block;
  font-family: "Lato", sans-serif;
  background-color: #fff;
  margin: 0 0 3rem 0;
  padding: 2rem 2rem;
  font-size: 3rem;
  color: #888;
  width: 100%;
  border: none;
  box-shadow: 0 1px 3px rgba(29, 31, 35, 0.3), 0 1px 3px rgba(9, 12, 17, 0.3);
}
.button-section {
  width: 75%;
  margin: 0 auto;
  display: flex;
}

.button-section p {
  font-size: 4rem;
  color: #666;
  font-weight: 500;
  padding-bottom: 2rem;
}

.button-item {
  display: flex;
  align-items: center;
  column-gap: 4rem;
}

/**************************/
/*                        */
/* max width below 110em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 110em) {
  .location-form {
    padding: 1rem 0rem;
  }

  .location-form label {
    font-size: 3rem;
  }
  .location-form span {
    margin-bottom: 1.5rem;
  }
  .location-form input {
    margin: 0 0 2rem 0;
    padding: 1rem 2rem;
    font-size: 2.5rem;
  }
  .location-form textarea {
    margin: 0 0 2rem 0;
    padding: 1rem 2rem;
    font-size: 2.5rem;
  }
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 80em) {
  .button-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
}

/**************************/
/*                        */
/* max width below 80em  */
/* or max width of 1760px */
/*                        */
/**************************/
@media (max-width: 50em) {
  .button-section {
    margin: 0 auto;
    display: flex;
  }
}
